@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&display=swap");

body {
  background: #f9fafb;
}

.App {
  text-align: center;
}
.wrap {
  overflow: hidden;
}
.App-header {
  background-color: #ffbc5c;
  /* background-blend-mode: lighten; */
  /* background-image: linear-gradient(135deg, #ffbc5c 0%, #ffb04c 100%); */
  padding: 5rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  background-size: 40%; /* 50px x 50pxにサイズを変更 */
  background-repeat: no-repeat; /* 繰り返しをしない */
  background-position: center center; /* 背景画像の中央に配置 */
}
/* for debug */
/* * {
  outline: 2px red solid;
} */
.App-header h1 {
  margin-right: calc((100vw - 200px) / -6);
  padding: 0 0 0 0;
  font-family: "Nunito Sans", sans-serif;
  /* 画面幅に合わせて文字間隔を調整して均等割付にする */
  letter-spacing: calc((100vw - 200px) / 6);
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  /* background: linear-gradient(transparent 65%, #fff 65%); */
  text-shadow: 3px 3px 5px #808080;
}
.App-header h2 {
  margin: 5rem 0 0 0;
  margin-right: calc((100vw - 250px) / -6);
  padding: 0 0 0 0;
  font-family: "Nunito Sans", sans-serif;
  /* 画面幅に合わせて文字間隔を調整して均等割付にする */
  letter-spacing: calc((100vw - 250px) / 6);
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  /* background: linear-gradient(transparent 65%, #fff 65%); */
  text-shadow: 3px 3px 5px #808080;
}

.App-header p {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #a28c6c;
}

.App-link {
  color: #61dafb;
}
.App-content {
  max-width: 60rem;
  margin: auto;
  text-align: left;
}
